import { WebStorageStateStore } from 'oidc-client-ts';
import type { ReactNode } from 'react';
import { AuthProvider } from 'react-oidc-context';

import { CONFIG } from '@library/config';

const {
  OIDC_AUTHORITY: authority,
  OIDC_CLIENT_ID: clientId,
  OIDC_CLIENT_SCOPE: scope,
  OIDC_REDIRECT_URI: redirectUri,
  OIDC_RESPONSE_TYPE: responseType,
  OIDC_CLIENT_ROOT: logoutUrl,
  OIDC_SILENT_REDIRECT_URI: silentRedirectUri
} = CONFIG;

const oidcConfig = {
  authority: authority,
  client_id: clientId,
  redirect_uri: redirectUri ?? `${window.location.origin}/userCallback`,
  response_type: responseType,
  scope,
  post_logout_redirect_uri: logoutUrl ?? `${window.location.origin}/`,
  silent_redirect_uri:
    silentRedirectUri ?? `${window.location.origin}/silent-renew.html`,
  userStore: new WebStorageStateStore({ store: localStorage }),
  automaticSilentRenew: true,
  loadUserInfo: true
};

const Provider = ({ children }: { children: ReactNode }) => {
  return <AuthProvider {...oidcConfig}>{children}</AuthProvider>;
};

export default Provider;
