import { Suspense } from 'react';

import BlueWorldMapLoader from '@components/Loading/BlueWorldMapLoader';
import useViewModel from './useViewModel';

const Home = () => {
  const Component = useViewModel();

  return (
    <Suspense fallback={<BlueWorldMapLoader />}>
      <Component />
    </Suspense>
  );
};

export default Home;
