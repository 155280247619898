import type { ReactNode } from 'react';
import { createContext, useMemo, useState } from 'react';

import type { ContextType, PageID } from './types';

export const Context = createContext({} as ContextType);

export const Provider = ({ children }: { children: ReactNode }) => {
  const [pageID, setPageID] = useState<PageID | null>(null);

  const state = useMemo(() => ({ pageID, setPageID }), [pageID]);

  return <Context.Provider value={state}>{children}</Context.Provider>;
};
