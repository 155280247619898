import type { ReactNode } from 'react';
import { BrowserRouter as Router, Routes } from 'react-router-dom';

import { GlobalStyle } from '@angloeastern/react-library';
import { Provider as ThemeProvider } from '@context/Theme';
import AppWrapper from '../../AppWrapper';
import LanguageHandler from '../../LanguageHandler';

const BasicShell = ({ children }: { children: ReactNode }) => {
  return (
    <ThemeProvider>
      <GlobalStyle />
      <Router>
        <LanguageHandler>
          <AppWrapper>
            <Routes>{children}</Routes>
          </AppWrapper>
        </LanguageHandler>
      </Router>
    </ThemeProvider>
  );
};

export default BasicShell;
