import axios from 'axios';

import { CONFIG } from '@library/config';
import { trackException } from '../../appinsights/index';

const {
  SVC_URL: baseURL,
  REQUEST_TIMEOUT: timeout = '180000',
  OIDC_AUTHORITY: authority
} = CONFIG;

const getAccessToken = () => {
  const storedToken = localStorage.getItem(`oidc.user:${authority}:spaclient`);
  return storedToken ? JSON.parse(storedToken).access_token : undefined;
};

const createHttpInstance = () => {
  const instance = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    timeout: parseInt(timeout, 10)
  });

  instance.interceptors.request.use(
    (config) => {
      const token = getAccessToken();
      if (token) {
        config.headers.Authorization = `BEARER ${token}`;
      }
      return config;
    },
    (error) =>
      Promise.reject(error instanceof Error ? error : new Error(String(error)))
  );

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      const json = error?.toJSON();
      const exception = {
        status: json?.status || 'Unknown Status',
        message: error?.message || 'Unknown Message',
        url: error?.config?.url || 'Unknown URL',
        lineNumber: 0,
        columnNumber: 0,
        error: json || error,
        stackDetails: error?.stack
      };

      trackException(exception);
      return Promise.reject(
        error instanceof Error ? error : new Error(String(error))
      );
    }
  );

  return instance;
};

const http = createHttpInstance();

export const createAbortController = () => new AbortController();

export default http;
