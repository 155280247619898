import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { TranslationNS } from '@hooks/useTranslator';
import { getDefaultLanguage } from '@utils/index';

// Custom language mapping
function mapCustomLanguage(language: string): string {
  const languageMap: Record<string, string> = {
    'zh-hk': 'zh-Hant' // Map zh-hk to Traditional Chinese
  };
  return languageMap[language] || language;
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['cookie', 'path', 'navigator'],
      caches: ['cookie']
    },
    fallbackLng: {
      'zh-hk': ['zh-Hant'], // Fallback for zh-hk to zh-Hant
      default: ['en'] // Default fallback language
    },
    supportedLngs: ['en', 'ja', 'zh-Hant'], // Include supported languages
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json' // Path for static assets in /public
    },
    lng: mapCustomLanguage(getDefaultLanguage()), // Map default language
    ns: [
      TranslationNS.COMMON,
      TranslationNS.CERTIFICATES,
      TranslationNS.CREW,
      TranslationNS.ERRORPAGE,
      TranslationNS.FINANCIAL,
      TranslationNS.KPIS,
      TranslationNS.LOGIN,
      TranslationNS.NAVIGATION,
      TranslationNS.NOTIFICATIONS,
      TranslationNS.REPORTS
    ],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: true
    }
  });

export default i18n;
