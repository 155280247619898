/*
 for your new environment variables, add them to the VITE_ prefixed environment variables in the .env.local file
*/
export const CONFIG = {
  CLARITY_ID: import.meta.env.VITE_CLARITY_ID ?? process.env.VITE_CLARITY_ID,
  OIDC_AUTHORITY:
    import.meta.env.VITE_OIDC_AUTHORITY ?? process.env.VITE_OIDC_AUTHORITY,
  OIDC_CLIENT_ID:
    import.meta.env.VITE_OIDC_CLIENT_ID ?? process.env.VITE_OIDC_CLIENT_ID,
  OIDC_CLIENT_SCOPE:
    import.meta.env.VITE_OIDC_CLIENT_SCOPE ??
    process.env.VITE_OIDC_CLIENT_SCOPE,
  OIDC_REDIRECT_URI:
    import.meta.env.VITE_OIDC_REDIRECT_URI ??
    process.env.VITE_OIDC_REDIRECT_URI,
  OIDC_RESPONSE_TYPE:
    import.meta.env.VITE_OIDC_RESPONSE_TYPE ??
    process.env.VITE_OIDC_RESPONSE_TYPE,
  OIDC_CLIENT_ROOT:
    import.meta.env.VITE_OIDC_CLIENT_ROOT ?? process.env.VITE_OIDC_CLIENT_ROOT,
  OIDC_SILENT_REDIRECT_URI:
    import.meta.env.VITE_OIDC_SILENT_REDIRECT_URI ??
    process.env.VITE_OIDC_SILENT_REDIRECT_URI,
  DEFAULT_LANGUAGE:
    import.meta.env.VITE_DEFAULT_LANGUAGE ?? process.env.VITE_DEFAULT_LANGUAGE,
  SUPPORTED_LANGUAGES:
    import.meta.env.VITE_SUPPORTED_LANGUAGES ??
    process.env.VITE_SUPPORTED_LANGUAGES,
  SVC_URL: import.meta.env.VITE_SVC_URL ?? process.env.VITE_SVC_URL,
  REQUEST_TIMEOUT:
    import.meta.env.VITE_REQUEST_TIMEOUT ?? process.env.VITE_REQUEST_TIMEOUT,
  APPLICATIONINSIGHTS_ROLENAME:
    import.meta.env.VITE_APPLICATIONINSIGHTS_ROLENAME ??
    process.env.VITE_APPLICATIONINSIGHTS_ROLENAME,
  FRESHDESK_ID:
    import.meta.env.VITE_FRESHDESK_ID ?? process.env.VITE_FRESHDESK_ID,
  PDFJS_CONFIG:
    import.meta.env.VITE_PDFJS_CONFIG ?? process.env.VITE_PDFJS_CONFIG,
  RASTER_MAP_ID:
    import.meta.env.VITE_RASTER_MAP_ID ?? process.env.VITE_RASTER_MAP_ID,
  VECTOR_MAP_ID:
    import.meta.env.VITE_VECTOR_MAP_ID ?? process.env.VITE_VECTOR_MAP_ID
};
