import type { ReactNode } from 'react';

import { GlobalStyle } from '@angloeastern/react-library';
import { Provider as ThemeProvider } from '@context/Theme';

export const StyleThemeProvider = ({ children }: { children: ReactNode }) => (
  <ThemeProvider>
    <GlobalStyle />
    {children}
  </ThemeProvider>
);

export default StyleThemeProvider;
