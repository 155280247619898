import { memoize } from '@angloeastern/react-library';
import currencyCodes from './currency.json';

interface CurrencyCodes {
  [key: string]: string;
}

export const isCurrencyCode = memoize((code?: string | null) => {
  return code && code in currencyCodes;
});

export const getCurrencyLocale = memoize((code?: string | null) => {
  return code === 'INR' ? 'en-IN' : 'en-US';
});

const getCurrencySymbol = memoize((code?: string | null) => {
  const codes: CurrencyCodes = currencyCodes;
  return code && code in currencyCodes ? codes[code] : codes['USD'];
});

export const formatNumber = ({
  number,
  fractions,
  locale
}: {
  number: number | null;
  fractions?: number;
  locale?: string;
}): string | undefined =>
  number?.toLocaleString(locale, {
    maximumFractionDigits: fractions ?? 2,
    minimumFractionDigits: fractions ?? 2
  });

export default getCurrencySymbol;
