import type { ReactNode } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useTranslator } from '@hooks/useTranslator';

const LanguageHandler = ({ children }: { children: ReactNode }) => {
  const { language, defaultLanguage, supportedLanguages, changeLanguage } =
    useTranslator();
  const location = useLocation();

  useEffect(() => {
    const pathLang = location.pathname.split('/')[1];
    const lang = supportedLanguages.includes(pathLang)
      ? pathLang
      : defaultLanguage;

    if (language !== lang) {
      changeLanguage(lang);
    }
  }, [location.pathname, language]);

  return <>{children}</>;
};

export default LanguageHandler;
