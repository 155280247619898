import type { Features } from '@components/index';

export enum Actions {
  FETCH_DATA = 'fetch-data',
  UPDATE = 'update',
  SHOW_MENU = 'show-menu',
  SHOW_FORM = 'show-form',
  SET_ERROR = 'set-error',
  SET_ERROR_UNAUTHORIZED = 'set-error-unauthorized'
}

export interface ProfileData {
  imageData: string;
  language: string;
}

export enum AccessType {
  READ = 'Read',
  READ_WRITE = 'Read/Write',
  DENIED = 'Denied'
}

export enum AccessTypeID {
  READ = 1,
  READ_WRITE = 2,
  DENIED = 3
}

export interface Feature {
  id?: number;
  featureName: string;
  parentId: number;
  parentFeatureName: string | null;
  accessTypeId: AccessTypeID;
  accessType: AccessType;
  beCode: Features;
}

export interface Permissions {
  featureList: Feature[];
  sourceSystems: string[];
}

export interface UserData {
  profile?: ProfileData;
  permissions?: Permissions;
  userDetails?: Array<string[]>;
}

export interface State {
  user: UserData | null;
  showMenu: boolean;
  showForm: boolean;
  error: boolean;
  unauthorized: boolean;
}

export type Action =
  | { type: Actions.FETCH_DATA; payload: UserData }
  | { type: Actions.UPDATE; payload: ProfileData }
  | { type: Actions.SHOW_MENU; payload: boolean }
  | { type: Actions.SHOW_FORM; payload: boolean }
  | { type: Actions.SET_ERROR_UNAUTHORIZED }
  | { type: Actions.SET_ERROR };
