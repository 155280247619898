import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { useDeviceCheck } from '@angloeastern/react-library';
import useEncryption from '@hooks/useEncryption';
import { errorTracker, isAbortError } from '@utils/errorTracker';
import { fetchConfig } from './api';

export const Context = createContext({});

export const Provider = ({ children }: { children: ReactNode }) => {
  const { decode64 } = useEncryption();
  const [config, setConfig] = useState({});
  const [error, setError] = useState(false);
  const deviceCheck = useDeviceCheck();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    async function fetchData() {
      try {
        const { data } = await fetchConfig(signal);
        setConfig(JSON.parse(decode64(data)));
      } catch (e: any) {
        if (!isAbortError(e)) {
          errorTracker(e);
          setError(true);
          window.location.href = '/error';
        }
      }
    }

    fetchData();

    return () => {
      controller.abort();
    };
  }, []);

  const state = useMemo(
    () => ({
      ...config,
      error,
      deviceCheck
    }),
    [config, error, deviceCheck]
  );

  return <Context.Provider value={state}>{children}</Context.Provider>;
};

export const useConfig = () => useContext<any>(Context);
