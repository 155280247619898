import type { DispatchWithoutAction, ReactNode } from 'react';
import { createContext, useMemo, useReducer } from 'react';

type BoundActions = {
  [x: string]: (dispatch: DispatchWithoutAction) => void;
};

const createDataContext = (reducer: any, actions: any, defaultValue: any) => {
  const Context = createContext({});

  const Provider = ({
    children,
    ...rest
  }: {
    children: ReactNode;
    [x: string]: any;
  }) => {
    const [state, dispatch] = useReducer(reducer, defaultValue);

    const boundActions = {} as BoundActions;
    for (const key in actions) {
      boundActions[key] = actions[key](dispatch);
    }

    const ctxState = useMemo(
      () => ({ state, ...boundActions, ...rest }),
      [state, boundActions, rest]
    );

    return <Context.Provider value={ctxState}>{children}</Context.Provider>;
  };

  return { Context, Provider };
};

export default createDataContext;
