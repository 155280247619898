import type { ReactNode } from 'react';
import { createContext, useMemo, useState } from 'react';

import type { ContextType } from './types';

export const Context = createContext({} as ContextType);

export const Provider = ({ children }: { children: ReactNode }) => {
  const [show, setShow] = useState(true);

  const openNotifications = () => setShow(true);

  const closeNotifications = () => setShow(false);

  const state = useMemo(
    () => ({ show, openNotifications, closeNotifications }),
    [show]
  );

  return <Context.Provider value={state}>{children}</Context.Provider>;
};
