import type { Dispatch } from 'react';

import { fetchOrganizations } from './api';
import type { Action, Organization, Organizations } from './types';
import { Actions } from './types';

export const fetchData =
  (dispatch: Dispatch<Action>) =>
  async (orgAeCodes: string[] | null, signal?: AbortSignal) => {
    try {
      const data: Organizations = await fetchOrganizations(orgAeCodes, signal);

      const orgs = data
        ? data
            .toSorted((a: Organization, b: Organization) => {
              if (a.name < b.name) return -1;
              else if (a.name > b.name) return 1;
              return 0;
            })
            .map((o: Organization, idx: number) => {
              o.sortKey = `index-${idx}`;
              return o;
            })
        : data;

      dispatch({ type: Actions.FETCH_DATA, payload: orgs });
    } catch (e: any) {
      dispatch({ type: Actions.SET_ERROR });
    }
  };

export const setCurrent =
  (dispatch: Dispatch<Action>) => (org: Organization) => {
    dispatch({ type: Actions.SET_CURRENT, payload: org });
  };

export const resetData = (dispatch: Dispatch<Action>) => () => {
  dispatch({ type: Actions.RESET_DATA });
};
