import type { Dispatch } from 'react';

import type { Action } from './types';
import { Actions } from './types';

export const showMessageBox =
  (dispatch: Dispatch<Action>) =>
  (content: React.ReactNode, title = null) => {
    dispatch({ type: Actions.SHOW, payload: { title, content } });
  };

export const hideMessageBox = (dispatch: Dispatch<Action>) => () => {
  dispatch({ type: Actions.HIDE });
};
