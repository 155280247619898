import type { IAutoExceptionTelemetry } from '@microsoft/applicationinsights-web';
import { trackException } from '../../appinsights';

export const isAbortError = (e: any) => {
  return (
    (e.name === 'AbortError' && e.message === 'canceled') ||
    (e.name === 'AxiosError' && e.message === 'Request aborted')
  );
};

export const errorTracker = (error: any) => {
  if (error) {
    console.error('Error << ', error.message);

    const json =
      typeof error.toJSON === 'function'
        ? error.toJSON()
        : {
            message: error
          };

    const exception: IAutoExceptionTelemetry = {
      // status: json?.status || 'Unknown Status',
      message: error?.message || 'Unknown Message',
      url: error?.config?.url || 'Unknown URL',
      lineNumber: 0,
      columnNumber: 0,
      error: json || error,
      stackDetails: error?.stack
    };

    trackException(exception);
  }
};
