import Sc from './style';

const BlueWorldMapLoader = ({
  top = 0,
  left = 0,
  widthOffset = 0
}: {
  top?: number;
  left?: number;
  widthOffset?: number;
}) => {
  const props = {
    $top: top,
    $left: left,
    $widthOffset: widthOffset
  };
  return (
    <Sc.LoaderOverlay {...props}>
      <Sc.MapOverlay data-testid="blue-map-loader" />
    </Sc.LoaderOverlay>
  );
};

export default BlueWorldMapLoader;
